import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-smm',
  templateUrl: './smm.component.html',
  styleUrls: ['./smm.component.scss']
})
export class SmmComponent implements OnInit {

  constructor(  
    private titleService: Title,  
    private metaTagService: Meta  
  ) { }  
    

  ngOnInit() {  
    this.titleService.setTitle("Avitrons Strategic Best SMM Services in USA");  
    
    this.metaTagService.addTags([  
      { name: 'description', content: 'Elevate your brands social presence with Avitrons SMM services in USA. Our strategic approach harnesses the power of social media to enhance visibility, engagement, and business growth for your American audience.'},  
      { name: 'robots', content: 'index, follow' },  
      { charset: 'UTF-8' } , 
    ]);  
        
  }  
}  
