<app-navbar-style-two></app-navbar-style-two>
<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Salesforce Lightning Components</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Salesforce Lightning</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/img/crm/SalesforceLightningComponentsBanner.jpg" alt="Images">
                        <h2>Empowering Solutions with Salesforce Lightning</h2>
                        <p>At Empowering Solutions, we leverage Salesforce Lightning to transform your business processes. Our expert team designs intuitive components that enhance user experience, boost productivity, and drive growth in your organization.</p>
                    </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceLightningComponents/Transforminguserexperience.png" alt="Images">
                                </div>
                                <h3>Transforming User Experience with Lightning</h3>
                                <p>Transform user experience with Salesforce Lightning’s intuitive design, ensuring seamless navigation and increased user engagement.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceLightningComponents/seamlessintegration.png" alt="Images">
                                </div>
                                <h3>Seamless Integration for Business Success</h3>
                                <p>Seamless integration with Salesforce Lightning enhances collaboration, streamlines processes, and drives overall business success.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceLightningComponents/customcomponents.png" alt="Images">
                                </div>
                                <h3>Custom Components for Unique Needs</h3>
                                <p>Our custom Salesforce Lightning components are tailored to meet your specific business requirements and enhance efficiency.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceLightningComponents/Boostproductivity.png" alt="Images">
                                </div>
                                <h3>Boosting Productivity with Lightning Solutions</h3>
                                <p>Boost productivity with Salesforce Lightning Solutions, designed for efficiency, collaboration, and streamlined business processes.</p>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/crm/SalesforceLightningComponentImage.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="content-widget-text">
                                    <h2>Salesforce Lightning Components Overview</h2>
                                    <ul>
                                        <li><i class='bx bx-check'></i> Enhanced User Experience</li>
                                        <li><i class='bx bx-check'></i> Customizable Component Design</li>
                                        <li><i class='bx bx-check'></i> Streamlined Business Processes</li>
                                        <li><i class='bx bx-check'></i> Seamless Integration Capabilities</li>
                                        <li><i class='bx bx-check'></i> Improved Collaboration Tools</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>Avitron will provide you with a website powered by Salesforce Lightning Components, enabling you to effectively showcase your products or services to potential customers. Seize the opportunity to boost your sales with our expertly crafted websites. Whether you need a new website or a revamp, we specialize in all aspects of Salesforce Lightning solutions.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <app-sidebar></app-sidebar>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer-style-two></app-footer-style-two>

