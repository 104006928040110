<app-navbar-style-two></app-navbar-style-two>
<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Salesforce Field Service Lightning</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Salesforce Field Lightning</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/img/crm/SalesforceFieldServiceLightningBanner.jpg" alt="Images">
                        <h2>Empowering Service Excellence with Salesforce</h2>
                        <p>Salesforce Field Service Lightning transforms on-site operations with real-time data, optimizing workforce management and enhancing customer experiences. Streamline scheduling, improve communication, and boost productivity for superior service delivery.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceFieldServiceLightning/fieldservice.png" alt="Images">
                                </div>
                                <h3>Revolutionize Field Service Operations Today</h3>
                                <p>Transform your field service operations with real-time insights, efficient scheduling, and improved customer engagement.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceFieldServiceLightning/steamlinesheduling.png" alt="Images">
                                </div>
                                <h3>Streamline Scheduling and Dispatch Effortlessly</h3>
                                <p>Streamline scheduling and dispatch with real-time insights, ensuring efficient resource allocation and timely service delivery.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceFieldServiceLightning/Enhancecostumer.png" alt="Images">
                                </div>
                                <h3>Enhance Customer Experience with Efficiency</h3>
                                <p>Enhance customer experience with efficiency by providing timely service, personalized solutions, and seamless communication.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceFieldServiceLightning/Optmizeworkforce.png" alt="Images">
                                </div>
                                <h3>Optimize Workforce Management for Success</h3>
                                <p>Optimize workforce management with real-time insights, empowering teams to deliver exceptional service efficiently and effectively.</p>
                            </div>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/crm/SalesforceFieldServiceLightningImage.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="content-widget-text">
                                    <h2>Transform Your Field Service Operations</h2>
                                    <ul>
                                        <li><i class='bx bx-check'></i> Enhance Customer Satisfaction </li>
                                        <li><i class='bx bx-check'></i> Real-Time Scheduling Made Simple</li>
                                        <li><i class='bx bx-check'></i> Empower Technicians with Mobile Tools</li>
                                        <li><i class='bx bx-check'></i> Data-Driven Insights for Decision Making</li>
                                        <li><i class='bx bx-check'></i> Streamline Communication Across Teams</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>Avitron will provide you with a website that empowers you to showcase your products or services to potential customers, leveraging Salesforce Field Service Lightning. Seize the opportunity to boost your sales with a tailored website. Whether you need a new site or a revamp, our expertise covers all your needs.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <app-sidebar></app-sidebar>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer-style-two></app-footer-style-two>
