<app-navbar-style-two></app-navbar-style-two>
<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Salesforce Einstein Analytics Service</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Salesforce Einstein Analytics</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/img/crm/SalesforceEinsteinBanner.jpg" alt="Images">
                        <h2>Empowering Insights with Einstein Analytics</h2>
                        <p>Empowering Insights with Einstein Analytics enables businesses to harness data-driven decision-making. Our powerful tools transform complex data into actionable insights, enhancing performance, optimizing strategies, and driving sustainable growth for your organization.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceEinsteinAnalyticsService/.png" alt="Images">
                                </div>
                                <h3>Drive Data-Driven Decisions with Einstein</h3>
                                <p>Leverage Salesforce Einstein Analytics to make informed decisions and optimize strategies for business success.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/newimg/FuturisticTechnology.png" alt="Images">
                                </div>
                                <h3>Unlock Insights for Strategic Business Growth</h3>
                                <p>Unlock insights to drive strategic growth, optimize operations, and enhance decision-making with Einstein Analytics.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/newimg/HighLevel.png" alt="Images">
                                </div>
                                <h3>Transforming Data into Actionable Insights</h3>
                                <p>Transforming data into actionable insights allows businesses to make informed decisions and drive impactful results.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/newimg/QuickSupport.png" alt="Images">
                                </div>
                                <h3>Empower Your Business with Analytics Solutions</h3>
                                <p>Empower your business with analytics solutions that transform data into insights, driving informed decision-making growth.</p>
                            </div>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/crm/SalesforceEinsteinAnalyticsImage.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="content-widget-text">
                                    <h2>Elevate Business with Analytics</h2>
                                    <ul>
                                        <li><i class='bx bx-check'></i> Data-Driven Insights Unleashed</li>
                                        <li><i class='bx bx-check'></i> Transformative Analytics Solutions</li>
                                        <li><i class='bx bx-check'></i> Intuitive Dashboard Visualizations</li>
                                        <li><i class='bx bx-check'></i> Real-Time Data Access</li>
                                        <li><i class='bx bx-check'></i> Enhanced Decision-Making Capabilities</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>Avitron will provide you with a website that empowers you to showcase your products or services to potential customers, leveraging Salesforce Einstein Analytics Service for data-driven insights. Seize the opportunity to boost your sales with an enhanced online presence. Whether you need a new website or want to revamp your existing one, we have the expertise in all services to help you succeed. </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <app-sidebar></app-sidebar>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer-style-two></app-footer-style-two>
