import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-zoho-crm-implementation-service',
  templateUrl: './zoho-crm-implementation-service.component.html',
  styleUrls: ['./zoho-crm-implementation-service.component.scss']
})
export class ZohoCRMImplementationServiceComponent implements OnInit {

  constructor(
    private titleService: Title,  
    private metaTagService: Meta 
  ) { }
  

  ngOnInit(): void {
    this.titleService.setTitle("Expert Zoho CRM Implementation Services USA");  
    
    this.metaTagService.addTags([  
      { name: 'description', content: 'Transform your business with our expert Zoho CRM implementation services in the USA. Streamline processes and enhance customer relationships.'},  
      { name: 'robots', content: 'index, follow' },  
      { charset: 'UTF-8' } , 
    ]); 
  }

}
