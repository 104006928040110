<app-navbar-style-two></app-navbar-style-two>
<div style="display: flex; justify-content: center; align-items: center; height: 100vh; margin: 0;" bis_skin_checked="1">
    <div class="thank-you-container" style="background-color: #fff; border-radius: 10px; padding: 20px; text-align: center;margin-top: 200px;" bis_skin_checked="1">
    <img decoding="async" style="width: 200px; display: block; margin: 0 auto;" src="https://media.tenor.com/Hw7f-4l0zgEAAAAM/check-green.gif" alt="Thank You GIF">
    <h1 style="color: #333;">Thank You for Submitting!</h1>
    <p style="font-size: 18px; color: #555;">Your submission has been received. We appreciate your feedback!</p>
    <a class="home-button" style="display: inline-block; padding: 10px 20px; background-color: #28a745; color: #fff; text-decoration: none; border-radius: 5px; margin-top: 20px; font-size: 16px;" href="/">Go to Home Page</a>
    </div>
    </div>

<app-footer-style-two></app-footer-style-two>

