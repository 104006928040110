<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Our Products</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Our Products</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>



<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <!-- <img src="assets/img/service/dm.jpg" alt="Images"> -->
                        <h2 class="product-page-avi-t">Our Best Products</h2>
                        <!-- <p>Promote your business with the most trusted and best digital marketing company. Lots of organizations make the mistake of ignoring the importance of digital marketing while promoting their products and services. Your audience expends a huge amount of time on the internet. The right plan will make your business achieve all the success.</p> -->
                    </div><br>

                    <div class="row">


                        <div class="col-lg-6 col-md-6">
                            <div class="case-study-card">
                                <a href="https://inspizone.info/" target="_blank"><img src="assets/img/service/tms.jpg" alt="Images" target="_blank"></a>
                                <div class="content">
                                    <h3><a href="https://inspizone.info/" target="_blank">TMS</a></h3>
                                    <span>Training Management System</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="case-study-card">
                                <a href="https://job4x.com/" target="_blank"><img src="assets/img/service/job4x-1.jpg" alt="Images" target="_blank"></a>
                                <div class="content">
                                    <h3><a href="https://job4x.com/" target="_blank">JOB4X</a></h3>
                                    <span>Employer and Job Seeker</span>
                                </div>
                            </div>
                        </div>



                        <div class="col-lg-6 col-md-6">
                            <div class="case-study-card">
                                <a href="https://eatochi.com/" target="_blank"><img src="assets/img/service/eatochi-11.jpg" alt="Images" target="_blank"></a>
                                <div class="content">
                                    <h3><a href="https://eatochi.com/" target="_blank">Eatochi QR MENU</a></h3>
                                    <span>See A Demo Online Menu</span>
                                </div>
                            </div>
                        </div>

                        

                        <div class="col-lg-6 col-md-6">
                            <div class="case-study-card">
                                <a href="https://schoolah.sg/" target="_blank"><img src="assets/img/service/dm.jpg" alt="Images" target="_blank"></a>
                                <div class="content">
                                    <h3><a href="https://schoolah.sg/" target="_blank">LMS</a></h3>
                                    <span>Learning Management System</span>
                                </div>
                            </div>
                        </div>






                        <div class="col-lg-6 col-md-6">
                            <div class="case-study-card">
                                <a href="https://employeas.com/panel" target="_blank"><img src="assets/img/service/emp.jpg" alt="Images" target="_blank"></a>
                                <div class="content">
                                    <h3><a href="https://employeas.com/panel" target="_blank">EMP</a></h3>
                                    <span>Employee Management System</span>
                                </div>
                            </div>
                        </div>



                        <div class="col-lg-6 col-md-6">
                            <div class="case-study-card">
                                <a href="https://singapore-directory.com/" target="_blank"><img src="assets/img/service/singapore.png" alt="Images" target="_blank"></a>
                                <div class="content">
                                    <h3><a href="https://singapore-directory.com/" target="_blank">Directory Portal</a></h3>
                                    <span>Singapore Directory </span>
                                </div>
                            </div>
                        </div>

                       

<!-- 
                        <div class="col-lg-4 col-sm-4">
                            <div class="services-content-card">
                                <img src="assets/img/service/crm-1.jpg" alt="Images">
                                <h3 class="product-title-avitrons">CRM</h3>
                                <div class="product-button-avi-page"><a href="https://crm.avitron.com.sg/admin/authentication" target="_blank">Quick Link</a></div>
                                
                             </div>
                        </div>


                        <div class="col-lg-6 col-md-6">
                            <div class="case-study-card">
                                <a routerLink="/case-study-details"><img src="assets/img/case-studies/eatochi-11.jpg" alt="Images"></a>
                                <div class="content">
                                    <h3><a routerLink="/case-study-details">Artificial Intelligence</a></h3>
                                    <span>Machine Learning</span>
                                </div>
                            </div>
                        </div>  -->




                        <!-- <div class="col-lg-12 col-md-12">
                            <div class="pagination-area text-center">
                                <a routerLink="#" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                                <span class="page-numbers current" aria-current="page">1</span>
                                <a routerLink="#" class="page-numbers">2</a>
                                <a routerLink="#" class="page-numbers">3</a>
                                <a routerLink="#" class="page-numbers">4</a>
                                <a routerLink="#" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                            </div>
                        </div> -->








                    </div>

                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <app-sidebar></app-sidebar>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-style-two></app-footer-style-two>