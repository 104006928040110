import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  constructor(  
    private titleService: Title,  
    private metaTagService: Meta  
  ) { }  
    

  ngOnInit() {  
    this.titleService.setTitle("Avitrons Products | Innovative Solutions for USA Businesses");  
    
    this.metaTagService.addTags([  
      { name: 'description', content: 'Discover a range of innovative products at Avitrons designed to enhance the efficiency of businesses in the USA. Explore our solutions and stay ahead in the digital landscape.'},  
      { name: 'robots', content: 'index, follow' },  
      { charset: 'UTF-8' } , 
    ]);  
        
  }  
}  
