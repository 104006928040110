import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-salesforce-developer-training',
  templateUrl: './salesforce-developer-training.component.html',
  styleUrls: ['./salesforce-developer-training.component.scss']
})
export class SalesforceDeveloperTrainingComponent implements OnInit {

  constructor(
    private titleService: Title,  
    private metaTagService: Meta 
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Salesforce Developer Training by Avitrons | Boost Your Skills");  
    
    this.metaTagService.addTags([  
      { name: 'description', content: 'Join Avitrons Salesforce Developer Training in the USA. Gain hands-on experience and become a certified Salesforce Developer. Enroll now for success'},  
      { name: 'robots', content: 'index, follow' },  
      { charset: 'UTF-8' } , 
    ]); 
  }

}
