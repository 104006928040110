import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-seo',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.scss']
})
export class SeoComponent implements OnInit {
  constructor(  
    private titleService: Title,  
    private metaTagService: Meta  
  ) { }  
    

  ngOnInit() {  
    this.titleService.setTitle("Avitrons SEO Services in the USA | Boost Your Website's Ranking and Visibility");  
    
    this.metaTagService.addTags([  
      { name: 'description', content: 'Enhance your online presence in the USA with Avitrons SEO services. Our expert team utilizes proven strategies to improve your websites ranking and visibility in the American market. Elevate your digital success with our dedicated SEO solutions.'},  
      { name: 'robots', content: 'index, follow' },  
      { charset: 'UTF-8' } , 
    ]);  
        
  }  
}  
