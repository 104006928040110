import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-salesforce-einstein-analytics-service',
  templateUrl: './salesforce-einstein-analytics-service.component.html',
  styleUrls: ['./salesforce-einstein-analytics-service.component.scss']
})
export class SalesforceEinsteinAnalyticsServiceComponent implements OnInit {

  constructor(
    private titleService: Title,  
    private metaTagService: Meta 
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Salesforce Einstein Analytics Service | Avitrons USA Experts");  
    
    this.metaTagService.addTags([  
      { name: 'description', content: 'Unlock insights with Salesforce Einstein Analytics. Avitrons USA offers data-driven solutions to boost your business performance with AI-powered analytics.'},  
      { name: 'robots', content: 'index, follow' },  
      { charset: 'UTF-8' } , 
    ]); 
  }

}
