import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-appdevelopment',
  templateUrl: './appdevelopment.component.html',
  styleUrls: ['./appdevelopment.component.scss']
})
export class AppdevelopmentComponent implements OnInit {

  constructor(  
    private titleService: Title,  
    private metaTagService: Meta  
  ) { }  
    

  ngOnInit() {  
    this.titleService.setTitle("Avitrons - App Development services in the USA");  
    
    this.metaTagService.addTags([  
      { name: 'description', content: 'Crafting success through unparalleled App Development services in the USA. Avitrons brings innovation to life, delivering tailored solutions to elevate your business. Transform your ideas into impactful applications with our expert team.'},  
      { name: 'robots', content: 'index, follow' },  
      { charset: 'UTF-8' } , 
    ]);  
        
  }  
}  
