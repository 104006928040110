<app-navbar-style-two></app-navbar-style-two>
<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Salesforce Administrator Training </h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Salesforce Administrator</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/img/crm/SalesforceAdministratorBanner.jpg" alt="Images">
                        <h2>Expert Salesforce Administrator Training Solutions</h2>
                        <p>Our Salesforce Administrator Training equips you with essential skills to manage, configure, and optimize Salesforce platforms. Gain hands-on experience, master best practices, and accelerate your career in Salesforce administration.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceAdministratorTraining/becomeacirtifiedsalesforce.png" alt="Images">
                                </div>
                                <h3>Become a Certified Salesforce Administrator</h3>
                                <p>Become a Certified Salesforce Administrator and enhance your skills to manage Salesforce effectively and efficiently.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceAdministratorTraining/EssentialsalesforceadminSkills.png" alt="Images">
                                </div>
                                <h3>Learn Essential Salesforce Admin Skills</h3>
                                <p>Gain crucial Salesforce admin skills, including platform configuration, user management, and data security techniques.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceAdministratorTraining/Handsonslaesforceadmintraining.png" alt="Images">
                                </div>
                                <h3>Hands-On Salesforce Admin Training Course</h3>
                                <p>Gain practical experience with real-world scenarios in our hands-on Salesforce Admin training course.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceAdministratorTraining/Mastersalesforce.png" alt="Images">
                                </div>
                                <h3>Master Salesforce Administration in Weeks</h3>
                                <p>Accelerate your career by mastering Salesforce administration skills in just weeks with our comprehensive training.</p>
                            </div>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/crm/SalesforceAdministrationImage.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="content-widget-text">
                                    <h2>Salesforce Administrator Training Program</h2>
                                    <ul>
                                        <li><i class='bx bx-check'></i> Comprehensive Course Content</li>
                                        <li><i class='bx bx-check'></i> Expert Instructors Available</li>
                                        <li><i class='bx bx-check'></i> Hands-On Learning Experience</li>
                                        <li><i class='bx bx-check'></i> Flexible Online Schedule</li>
                                        <li><i class='bx bx-check'></i> Certification Preparation Included</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>Avitron offers a website that empowers you to showcase your Salesforce Administrator Training to potential customers. Seize the opportunity to boost your sales with an engaging online presence. Whether you need a new site or a revamp, we have the expertise to deliver exceptional results.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <app-sidebar></app-sidebar>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer-style-two></app-footer-style-two>

