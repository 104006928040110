import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-salesforce-lightning-components',
  templateUrl: './salesforce-lightning-components.component.html',
  styleUrls: ['./salesforce-lightning-components.component.scss']
})
export class SalesforceLightningComponentsComponent implements OnInit {

  constructor(
    private titleService: Title,  
    private metaTagService: Meta 
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Avitrons: Expert Salesforce Lightning Components Services");  
    
    this.metaTagService.addTags([  
      { name: 'description', content: 'Boost your business with Avitrons Salesforce Lightning Components. Tailored solutions for enhanced performance and user experience in the USA.'},  
      { name: 'robots', content: 'index, follow' },  
      { charset: 'UTF-8' } , 
    ]);
  }

}
