import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-salesforce-field-service-lightning',
  templateUrl: './salesforce-field-service-lightning.component.html',
  styleUrls: ['./salesforce-field-service-lightning.component.scss']
})
export class SalesforceFieldServiceLightningComponent implements OnInit {

  constructor(
    private titleService: Title,  
    private metaTagService: Meta 
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Salesforce Field Service Lightning Services | Avitrons USA");  
    
    this.metaTagService.addTags([  
      { name: 'description', content: 'Optimize field operations with Salesforce Field Service Lightning. Avitrons offers seamless integration, scheduling, and support in the USA.'},  
      { name: 'robots', content: 'index, follow' },  
      { charset: 'UTF-8' } , 
    ]); 
  }

}
