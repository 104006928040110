<footer class="footer-area footer-bg2 pt-100">
    <div class="container">
        <div class="footer-midal pb-70">
            <div class="row">
                <div class="col-lg-4 col-sm-7">
                    <div class="footer-widget">
                        <div class="footer-img">
                            <img src="assets/img/logo/logo1.png" alt="Images">
                        </div>
                        <p>Avitron Marke was established in the year 2018, which spends significant time in Search Engine Optimization, Digital Marketing, Ecommerce Advancement, Web Application, Custom Development and Blaze.</p>
                        <div class="footer-social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-5">
                    <div class="footer-widget">
                        <h3>Services</h3>

                        <ul class="footer-list">
                            <li class="nav-item"><a routerLink="/webdevelopment">Web Development</a></li>
                                <li class="nav-item"><a routerLink="/appdevelopment" >App Development</a></li>
                                <li class="nav-item"><a routerLink="/digitalmarketing">Digital Marketing</a></li>
                                <li class="nav-item"><a routerLink="/seo">S.E.O</a></li>
                                <li class="nav-item"><a routerLink="/smm">Social Media Marketing</a></li>
                                <li class="nav-item"><a routerLink="/graphicdesigning">Graphic Designing</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-7">
                    <div class="footer-widget pl-5">
                        <h3>Quick Links</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/">Home</a></li>
                            <li><a routerLink="/about">About</a></li>
                            <li><a routerLink="/products">Our Products</a></li>
                            <!-- <li><a routerLink="/case-study">Case Studies</a></li> -->
                            <li><a routerLink="/blog">Our Blog</a></li>
                            <li><a routerLink="/contact">Contact</a></li>
                            <li><a routerLink="/privacypolic">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-5">
                    <div class="footer-widget">
                        <h3>Address</h3>

                        <ul class="footer-list-two">
                            <li><i class='flaticon-telephone'></i><a href="tel:+16123801233">+1 612 380 1233</a></li>
                            <li><i class='flaticon-email-1'></i><a href="mailto:info@avitrons.com">info@avitrons.com</a></li>
                            <li><i class='flaticon-place'></i>4810 Ranchview Ln N Plymouth, MN 55446</li>
                        </ul>
                        <!-- <div class="container-fluid m-0 p-0"> -->
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2819.1737353550616!2d-93.47926248445665!3d45.04169617909825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52b3490cbd51fb15%3A0x7c0342672daffd5b!2s4810%20Ranchview%20Ln%20N%2C%20Plymouth%2C%20MN%2055446!5e0!3m2!1sen!2sus!4v1662972827469!5m2!1sen!2sus"></iframe>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="copy-right-area">
            <div class="row">
                <div class="col-lg-8">
                    <div class="copy-right-text text-start">
                        <p>© Copyright 2018-2022. || All Rights Reserved || <a href="#" target="_blank"> Avitron Marke Pte Ltd</a> </p>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="copy-right-list">
                        <ul>
                            <li><a routerLink="/privacy-policy"> Privacy Policy</a></li>
                            <!-- <li><a routerLink="/terms-conditions"> Terms & Conditions</a></li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>