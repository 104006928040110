import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-digitalmarketing',
  templateUrl: './digitalmarketing.component.html',
  styleUrls: ['./digitalmarketing.component.scss']
})
export class DigitalmarketingComponent implements OnInit {

  constructor(  
    private titleService: Title,  
    private metaTagService: Meta  
  ) { }  
    

  ngOnInit() {  
    this.titleService.setTitle("Avitrons | Strategic Digital Marketing Solutions for USA Businesses");  
    
    this.metaTagService.addTags([  
      { name: 'description', content: 'Unlock success with Avitrons Digital Marketing Solutions in USA. Elevate your online presence, drive engagement, and achieve your business goals with our strategic and tailored approach.'},  
      { name: 'robots', content: 'index, follow' },  
      { charset: 'UTF-8' } , 
    ]);  
        
  }  
}  
