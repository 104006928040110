import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/blog.service';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

	blogPosts: any;
	id!: string;
	errorMessage: any;
  
  constructor(
   private blogService: BlogService,
   private titleService: Title,  
    private metaTagService: Meta 
  ) { }

  ngOnInit() {
    this.getPosts();
    this.titleService.setTitle("Avitrons Blog | Stay Informed on Tech Trends for USA Businesses");  
    
    this.metaTagService.addTags([  
      { name: 'description', content: 'Read insightful articles on tech trends, design inspirations, and marketing strategies at Avitrons Blog. Stay informed and inspired to navigate the ever-evolving digital landscape in the USA.'},  
      { name: 'robots', content: 'index, follow' },  
      { charset: 'UTF-8' } , 
    ]); 
  }
  
  getPosts() {
   
    this.blogService.getPosts().subscribe(
      (data) => {
        this.blogPosts = data;
        console.log(this.blogPosts);
       
      },
      (error) => {
        // if any error, Code throws the error
        this.errorMessage = error.error.message;
        console.log(error.error.message, 'error');
        
      }
    );
  }
  
  
}
