<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>App Development</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>App Development</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>


<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/newimg/AppDevelopment.jpg" alt="Images">
                        <h2>App Development</h2>
                        <p>Avitron is one of the best and most affordable app development company that offers all phases of mobile app design, integration, and development services. We provide app development solutions to all startups to enterprises and our developers build mobile apps that improve user engagement.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/newimg/IOSApp.png" alt="Images">
                                 </div>
                                <h3>iOS App Development</h3>
                                <p>Our professionals develop full-fledged iOS mobile apps that are stable, scalable, and compatible with iPhone, IPad, Apple TV, and Apple watches.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/newimg/AndroidApp.png" alt="Images">
                                 </div>
                                <h3>Android App Development</h3>
                                <p>Our highly skilled professionals know the mysterious recipe to create applications that fulfill all your requirements and are compatible with Smartphones, Android Tablets, Android Wear, Android TV.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/newimg/NativeApp.png" alt="Images">
                                 </div>
                                <h3>Native App Development</h3>
                                <p>Our expert developers develop native apps with robust and evolutionary architecture using all technologies.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/newimg/HybridApp.png" alt="Images">
                                 </div>
                                <h3>Hybrid App Development</h3>
                                <p>Our experts developed hybrid apps that assess you reduce costs with a single code base and sped up time to market.</p>
                            </div>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/newimg/Appdevelopmensingle.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="content-widget-text">
                                    <h2>The Entire Solution For Content Marketer</h2>
                                    <ul>
                                        <li><i class='bx bx-check'></i> Improve accessibility </li>
                                        <li><i class='bx bx-check'></i> Better engagement </li>
                                        <li><i class='bx bx-check'></i> Valuable for customers </li>
                                        <li><i class='bx bx-check'></i> Building brand awareness </li>
                                        <li><i class='bx bx-check'></i> Improves customer relationship </li>
                                       
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>Our experienced experts develop all types of application in order to be user-friendly, easy to navigate, and contains attractive visuals. We offer all types of applications, whether you want an Android, ios application, or cross-platform application.</p>

                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <app-sidebar></app-sidebar>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>