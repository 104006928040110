import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-salesforce-development-service',
  templateUrl: './salesforce-development-service.component.html',
  styleUrls: ['./salesforce-development-service.component.scss']
})
export class SalesforceDevelopmentServiceComponent implements OnInit {

  constructor(
    private titleService: Title,  
    private metaTagService: Meta 
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Salesforce Development Services | Avitrons - USA Experts");  
    
    this.metaTagService.addTags([  
      { name: 'description', content: 'Avitrons offers expert Salesforce development services in the USA. Tailored solutions to optimize, integrate, and scale your business with Salesforce.'},  
      { name: 'robots', content: 'index, follow' },  
      { charset: 'UTF-8' } , 
    ]); 
  }

}
