<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Search Engine Optimization</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Search Engine Optimization</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/newimg/SEOBanner.jpg" alt="Images">
                        <h2>Content Marketing</h2>
                        <p>We provide the best service to optimize your website for search engines (SEO) or provide specific tips. Here are the primary areas involved in SEO services:
                            Seo efforts that benefit both the users search experience and page ranking by featured content that fulfills user seach needs. SEO, or Search Engine Optimization, is the process of increasing a website to improve its visibility and ranking on search engine results pages (SERPs) like Google, Bing, and Yahoo. The primary goal of SEO is to attract more organic (non-paid) traffic to your website by making it more attractive to both search engines and users. </p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/newimg/OnPage.png" alt="Images">
                                    </div>
                                <h3>On-Page SEO</h3>
                                <p>Refers to the practice of optimizing individual web pages to rank higher and earn more relevant traffic from search engines.  On-page seo is everything that you can directly control on your websites including content, title tag, keywords usage seo optimization urls, internal links and image all text optimization to visible content and the HTML source code .  </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/newimg/OffPage.png" alt="Images">
                                    </div>
                                <h3>Off-Page SEO</h3>
                                <p>Refers to all the activities and efforts you make outside of your website to improve its rankings on search engine results pages (SERPs). Off seo are actions that happen away from your websites like links and mentions on other websites. While On-Page SEO focuses on optimizing elements within your website, Off-Page SEO involves external signals that demonstrate your website's authority, relevance, and trustworthiness.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/newimg/LocalSEO.png" alt="Images">
                                    </div>
                                <h3>Local SEO-</h3>
                                <p>Local seo targeted towards users and potential customers who are interested in local search for a business products or services. This includes location-based queries and results.  </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/newimg/SEOii.png" alt="Images">
                                    </div>
                                <h3>Technical SEO- </h3>
                                <p>Technical SEO refers to optimizing the technical aspects of your website to ensure it meets the requirements of modern search engines, making it easier for them to crawl, index, and understand your site. Unlike On-Page SEO (which focuses on content) and Off-Page SEO (which focuses on external signals like backlinks), Technical SEO is about improving the backend and infrastructure of your website to improve organic rankings.</p>
                            </div>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/newimg/SEOImage.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="content-widget-text">
                                    <h2>The Entire Solution For  Search Engine Optimization</h2>  
                                    <ul>
                                        <li><i class='bx bx-check'></i> Increase leads</li>
                                        <li><i class='bx bx-check'></i> Boost Website Speed</li>
                                        <li><i class='bx bx-check'></i> Optimize for Mobile Devices </li>
                                        <li><i class='bx bx-check'></i>Increase  the visibility of your brand. </li>
                                        <li><i class='bx bx-check'></i> Increase your local search visibility. </li>
                                        <li><i class='bx bx-check'></i> Improve Conversion Rates </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>Social media marketing helps you to connect with your audience and assist them to understand your brand better. It is very useful to your business growth.</p>

                    <!-- <div class="services-widget-list">
                        <ul>
                            <li class="active">
                                <span>75%</span>
                                <div class="content list1">
                                    <h3>SEO & Marketing</h3>
                                </div>
                            </li>
                            <li>
                                <div class="content list2">
                                    <h3>Keywords Results</h3>
                                </div>
                                <span>66%</span>
                            </li>
                            <li>
                                <span>43%</span>
                                <div class="content list3">
                                    <h3>Google Analytics</h3>
                                </div>
                            </li>
                            <li>
                                <div class="content list2">
                                    <h3>Off Page SEO</h3>
                                </div>
                                <span>15%</span>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                   <app-sidebar></app-sidebar>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer-style-two></app-footer-style-two>