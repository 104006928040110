import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-webdevelopment',
  templateUrl: './webdevelopment.component.html',
  styleUrls: ['./webdevelopment.component.scss']
})
export class WebdevelopmentComponent implements OnInit {

  constructor(  
    private titleService: Title,  
    private metaTagService: Meta  
  ) { }  
    

  ngOnInit() {  
    this.titleService.setTitle("Web Development Services in USA by Avitrons | Tailored Solutions for American Businesses");  
    
    this.metaTagService.addTags([  
      { name: 'description', content: 'Avitrons provides specialized web development services in USA, crafted exclusively for American businesses. Elevate your online presence with our responsive and high-performing solutions. Transform your digital landscape with Avitrons.'},  
      { name: 'robots', content: 'index, follow' },  
      { charset: 'UTF-8' } , 
    ]);  
        
  }  
}  
