<app-navbar-style-two></app-navbar-style-two>
<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Web Development</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Web Development</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">

                <div class="services-left">
                    <div class="services-content">
                     <div class=".scrole1">
                        <img src="assets/newimg/WebDevelopment.jpg" alt="Images">
                        <h2>Creating an Engaging & Amazing Websites</h2>
                        <p>We’re a top-notch web development company in India. Our expert team of professionals is devoted to offering highly interactive, professional, user-friendly, SEO-friendly websites. We recognise the importance of having an engaging website in today’s digital world that will help you reach out to an extensive range of customers that you hadn’t accessed or interacted with before.</p>
                     </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceDevelopmentService/tranceform-your-crm-experience.png" alt="Images">
                                </div>
                                <h3>Cost-Efficient Solutions</h3>
                                <p>Avitron Web Development Services are affordable and offer you a great website and user experience. Our experts will decrease your website design and development costs with our cost-efficient management service</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceDevelopmentService/maximize-crm-efficiency.png" alt="Images">
                                </div>
                                <h3>Futuristic Technology</h3>
                                <p>Avitron uses the latest & futuristic technologies to develop a website. We use Angular, Node JS, React JS, PHP, Python, etc.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceDevelopmentService/enhance-user-experience.png" alt="Images">
                                </div>
                                <h3>High-level Security</h3>
                                <p>At Avitron, we understand the importance of data and website security. We offer you cutting-edge solutions that secure your website from hackers.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceDevelopmentService/custom-salesforce-development.png" alt="Images">
                                </div>
                                <h3>Quick Support & Maintenance</h3>
                                <p>At Avitron, we understand the importance of support and maintenance. We’re available for you around the clock to asses you and fix any issues that come.</p>
                            </div>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/newimg/Webdevelopmentsingle.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="content-widget-text">
                                    <h2>The complete solutions for your web development needs</h2>
                                    <ul>
                                        <li><i class='bx bx-check'></i> Reach more potential audience</li>
                                        <li><i class='bx bx-check'></i> Increase Sales</li>
                                        <li><i class='bx bx-check'></i> Ease and convenience for customers</li>
                                        <li><i class='bx bx-check'></i> Long-term client relations</li>
                                        <li><i class='bx bx-check'></i> Building Client’s trust</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>Avitron will offer you a website that empowers you to showcase your products or services to your potential customers. Seize the opportunity to boost your sales with websites. Whether you want a new website or want to revamp it, we’ve expertise in all services.</p>

                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <app-sidebar></app-sidebar>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer-style-two></app-footer-style-two>