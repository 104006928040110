import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-graphicdesigning',
  templateUrl: './graphicdesigning.component.html',
  styleUrls: ['./graphicdesigning.component.scss']
})
export class GraphicdesigningComponent implements OnInit {

  constructor(  
    private titleService: Title,  
    private metaTagService: Meta  
  ) { }  
    

  ngOnInit() {  
    this.titleService.setTitle("Avitrons Graphic Designing Services in the USA | Elevate Your Brand with Creative Excellence");  
    
    this.metaTagService.addTags([  
      { name: 'description', content: 'Elevate your brands visual identity with Avitrons top-tier graphic designing services in the USA. Our creative expertise crafts impactful designs tailored to amplify your brand presence and captivate your audience.'},  
      { name: 'robots', content: 'index, follow' },  
      { charset: 'UTF-8' } , 
    ]);  
        
  }  
}  
