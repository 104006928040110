<app-navbar-style-two></app-navbar-style-two>
<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Salesforce Development Service</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Salesforce Development</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/img/crm/Salesforce-Development-Banner.jpg" alt="Images">
                        <h2>Optimize Your CRM with Salesforce Development</h2>
                        <p>Enhance your CRM capabilities with custom Salesforce development. Streamline processes, improve customer relationships, and boost productivity by leveraging tailored Salesforce solutions designed to meet your business needs.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceDevelopmentService/maximize-crm-efficiency.png" alt="Images">
                                </div>
                                <h3>Maximize CRM Efficiency with Salesforce Development</h3>
                                <p>Boost your CRM efficiency through tailored Salesforce development, streamlining processes and enhancing customer engagement.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceDevelopmentService/enhance-user-experience.png" alt="Images">
                                </div>
                                <h3>Transform Your CRM Experience with Salesforce Solutions</h3>
                                <p>Revolutionize your CRM experience with Salesforce solutions tailored to boost productivity and enhance customer engagement.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceDevelopmentService/custom-salesforce-development.png" alt="Images">
                                </div>
                                <h3>Custom Salesforce Development for Enhanced CRM</h3>
                                <p>Unlock the full potential of your CRM with custom Salesforce development tailored to your business needs.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceDevelopmentService/tranceform-your-crm-experience.png" alt="Images">
                                </div>
                                <h3>Boost CRM Performance with Salesforce Development</h3>
                                <p>Enhance your CRM's capabilities with Salesforce development for improved efficiency and customer satisfaction.</p>
                            </div>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/crm/Salesforce-Development-Image.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="content-widget-text">
                                    <h2>Expert Salesforce Development Services</h2>
                                    <ul>
                                        <li><i class='bx bx-check'></i> Boost Sales and Efficiency</li>
                                        <li><i class='bx bx-check'></i> Streamlined CRM Integration</li>
                                        <li><i class='bx bx-check'></i> Seamless System Integration</li>
                                        <li><i class='bx bx-check'></i> Enhanced User Experience</li>
                                        <li><i class='bx bx-check'></i> Ongoing Support and Maintenance</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>Avitron offers you a website that empowers you to showcase your products or services effectively. With our Salesforce Development Service, seize the opportunity to boost your sales through a powerful online presence. Whether you need a new website or want to revamp your existing one, we have the expertise to deliver exceptional results.</p>

                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <app-sidebar></app-sidebar>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer-style-two></app-footer-style-two>