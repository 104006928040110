<app-navbar-style-two></app-navbar-style-two>
<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Salesforce Marketing Cloud Service</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Salesforce Marketing Cloud</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/img/crm/SalesforceMarketingCloudBanner.jpg" alt="Images">
                        <h2>Empowering Your Marketing with Salesforce</h2>
                        <p>Salesforce Marketing Cloud Service enhances customer engagement through personalized marketing strategies. With powerful analytics, automation, and integrated tools, it empowers businesses to deliver targeted campaigns and optimize their marketing efforts effectively.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceMarketingCloudService/Elevateyourmarkerting.png" alt="Images">
                                </div>
                                <h3>Elevate Your Marketing with Salesforce</h3>
                                <p>Elevate your marketing strategy with Salesforce, leveraging automation and insights for targeted, impactful campaigns.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceMarketingCloudService/personalizedcampaign.png" alt="Images">
                                </div>
                                <h3>Personalized Campaigns for Maximum Impact</h3>
                                <p>Create tailored campaigns that resonate with your audience, driving engagement and boosting conversion rates effectively.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceMarketingCloudService/DataDriveninsights.png" alt="Images">
                                </div>
                                <h3>Data-Driven Insights for Better Engagement</h3>
                                <p>Unlock the power of data-driven insights to enhance customer engagement and drive impactful marketing strategies.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceMarketingCloudService/Automatemarketing.png" alt="Images">
                                </div>
                                <h3>Automate Marketing for Greater Efficiency</h3>
                                <p>Automate your marketing processes with Salesforce to save time, enhance productivity, and drive better results.</p>
                            </div>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/crm/SalesforceMarketingCloudImage.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="content-widget-text">
                                    <h2>Salesforce Marketing Cloud Solutions</h2>
                                    <ul>
                                        <li><i class='bx bx-check'></i> Personalized Customer Experiences</li>
                                        <li><i class='bx bx-check'></i> Data-Driven Marketing Insights</li>
                                        <li><i class='bx bx-check'></i> Seamless Campaign Automation</li>
                                        <li><i class='bx bx-check'></i> Multi-Channel Engagement Strategies</li>
                                        <li><i class='bx bx-check'></i> Robust Analytics and Reporting</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>Avitron offers a website that empowers you to showcase your products or services using Salesforce Marketing Cloud Service. Seize the opportunity to boost your sales with a tailored website. Whether you need a new site or a revamp, our expertise covers all aspects of web development.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <app-sidebar></app-sidebar>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer-style-two></app-footer-style-two>
