import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-salesforce-sales-cloud-service',
  templateUrl: './salesforce-sales-cloud-service.component.html',
  styleUrls: ['./salesforce-sales-cloud-service.component.scss']
})
export class SalesforceSalesCloudServiceComponent implements OnInit {

  constructor(
    private titleService: Title,  
    private metaTagService: Meta 
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Salesforce Sales Cloud Service | Avitrons USA");  
    
    this.metaTagService.addTags([  
      { name: 'description', content: 'Boost your sales with Avitrons Salesforce Sales Cloud Service. Transform your business and drive growth with expert solutions in the USA.'},  
      { name: 'robots', content: 'index, follow' },  
      { charset: 'UTF-8' } , 
    ]); 
  }

}
