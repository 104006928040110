import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-salesforce-administrator-training',
  templateUrl: './salesforce-administrator-training.component.html',
  styleUrls: ['./salesforce-administrator-training.component.scss']
})
export class SalesforceAdministratorTrainingComponent implements OnInit {

  constructor(
    private titleService: Title,  
    private metaTagService: Meta 
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Salesforce Admin Training - Avitrons USA");  
    
    this.metaTagService.addTags([  
      { name: 'description', content: 'Learn Salesforce administration with Avitrons. Gain skills to manage, customize, and optimize Salesforce. Enroll in our expert-led training today!'},  
      { name: 'robots', content: 'index, follow' },  
      { charset: 'UTF-8' } , 
    ]); 
  }

}
