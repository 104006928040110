<app-navbar-style-two></app-navbar-style-two>
<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Salesforce CPQ Service</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Salesforce CPQ</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/img/crm/Salesforce-CPQ-Banners.jpg" alt="Images">
                        <h2>Unlocking Sales Efficiency with Salesforce CPQ</h2>
                        <p>Discover how Salesforce CPQ streamlines your sales process, enabling faster quotes, accurate pricing, and improved efficiency. Transform your sales team’s performance and enhance customer satisfaction with our tailored solutions.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceCPQService/streamlinequoteswithsalesforcecpq.png" alt="Images">
                                </div>
                                <h3>Streamline Quotes with Salesforce CPQ</h3>
                                <p>Salesforce CPQ automates quoting processes, ensuring speed, accuracy, and consistency for faster sales conversions.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceCPQService/boostsalesefficiency.png" alt="Images">
                                </div>
                                <h3>Boost Sales Efficiency and Accuracy</h3>
                                <p>Enhance your sales workflow with Salesforce CPQ, ensuring precise quotes and faster deal closures every time.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceCPQService/simplifypricing.png" alt="Images">
                                </div>
                                <h3>Simplify Pricing for Seamless Transactions</h3>
                                <p>Simplify pricing structures with Salesforce CPQ, ensuring quick, accurate quotes for seamless customer transactions.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceCPQService/empoweryoursalesteam.png" alt="Images">
                                </div>
                                <h3>Empower Your Sales Team Today</h3>
                                <p>Simplify pricing structures with Salesforce CPQ, ensuring quick, accurate quotes for seamless customer transactions.</p>
                            </div>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/crm/Salesforce-CPQ-Image.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="content-widget-text">
                                    <h2>Salesforce CPQ Solutions Simplified</h2>
                                    <ul>
                                        <li><i class='bx bx-check'></i> Effortless Quote Generation</li>
                                        <li><i class='bx bx-check'></i> Dynamic Pricing Models</li>
                                        <li><i class='bx bx-check'></i> Streamlined Approval Processes</li>
                                        <li><i class='bx bx-check'></i> Enhanced Sales Forecasting</li>
                                        <li><i class='bx bx-check'></i> Integrated Product Configurations</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>Avitron will provide you with a website that empowers you to showcase your products or services effectively. Leverage our Salesforce CPQ Service to seize opportunities and boost your sales. Whether you need a new website or want to revamp your existing one, we have the expertise to deliver exceptional results.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <app-sidebar></app-sidebar>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer-style-two></app-footer-style-two>
