import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BlogService } from 'src/app/blog.service';

@Component({
  selector: 'app-ai-startup',
  templateUrl: './ai-startup.component.html',
  styleUrls: ['./ai-startup.component.scss']
})
export class AiStartupComponent implements OnInit, AfterViewInit {

  blogPosts: any;
  id!: string;
  errorMessage: any;

  @ViewChild('myVideo') myVideo: ElementRef; // ViewChild to access the video element

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private blogService: BlogService,
    private titleService: Title,
    private metaTagService: Meta 
  ) { }

  ngOnInit() {
    this.getPosts();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      const rt = this.getChild(this.activatedRoute);
      rt.data.subscribe(data => {
        console.log(data);
      });
    });

    // Set page title and meta tags
    this.titleService.setTitle("Avitrons | Leading Digital Solutions for Businesses in the USA");  
    
    this.metaTagService.addTags([  
      { name: 'description', content: 'Explore Avitrons for cutting-edge web development, digital marketing, and innovative solutions tailored for businesses in the USA. Elevate your online presence with our expert team.' },  
      { name: 'robots', content: 'index, follow' },  
      { charset: 'UTF-8' }  
    ]);
  }

  ngAfterViewInit() {
    // Access video element and play it after view initialization
    const videoElement: HTMLVideoElement = this.myVideo.nativeElement;
    videoElement.muted = true;  // Ensure video is muted for autoplay
    videoElement.play();        // Autoplay the video
  }

  getPosts() {
    this.blogService.getPosts().subscribe(
      (data) => {
        this.blogPosts = data;
        console.log(this.blogPosts);
      },
      (error) => {
        this.errorMessage = error.error.message;
        console.log(error.error.message, 'error');
      }
    );
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
}
