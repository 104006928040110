<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo/logo1.png" alt="Logo"></a>
    </div>

    <div class="main-nav nav-bar-two">
        <div class="container-fluid">
            <nav class="container-max-2 navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo/logo2.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a>

                        </li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link">About</a></li>


                        <li class="nav-item dmenu">
                            <a href="javascript:void(0);" class="nav-link">Services <i class='bx bx-plus'></i></a>

                            <ul class="dropdown-menu">
                                <!-- <li class="nav-item"><a routerLink="/webdevelopment">Web Development</a></li> -->

                                <div class="wpmm-sub-menu-wrap">
                                    <div><ul>
                                    <li class="wpmega-1columns-2total">
                                        
                                        <a class="wp-mega-menu-link" routerLink="/webdevelopment"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/webdev.png" width="30" height="30"></span><span><b>Web Development</b> <br> <p>We create an engaging website</p></span></a></li>
                                    
                                    
                                    <li class="wpmega-1columns-2total">
                                        
                                        <a class="wp-mega-menu-link" routerLink="/appdevelopment"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/appdev.png" width="30" height="30"></span><span><b>App Development</b> <br> <p>We develop robust and secure applications</p></span></a></li>
                                    
                                    <li class="wpmega-1columns-2total">
                                        
                                        <a class="wp-mega-menu-link" routerLink="/digitalmarketing"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/dm.png" width="30" height="30"></span><span><b>Digital Marketing</b> <br> <p>We create an unbeatable digital marketing strategy</p></span></a></li>
                                        
                                        
                                    <li class="wpmega-1columns-2total">
                                            
                                        <a class="wp-mega-menu-link" routerLink="/graphicdesigning"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/graphic.png" width="30" height="30"></span><span><b>Graphic Designing</b> <br> <p>Create graphics that tell your brands </p></span></a></li>
                                    
                                    <li class="wpmega-1columns-2total">
                                        
                                        <a class="wp-mega-menu-link" routerLink="/seo"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/seo.png" width="30" height="30"></span><span><b>Search Engine Optimization</b> <br> <p>Be No. 1 on the Search Engine Results Page (SERP)</p></span></a></li>
                                        
                                        
                                    <li class="wpmega-1columns-2total">
                                            
                                        <a class="wp-mega-menu-link" routerLink="/smm"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/smm.png" width="30" height="30"></span><span><b>Social Media Marketing</b> <br> <p>We do successful creative social media marketing</p></span></a></li>

                                </ul></div>
                                </div>
                               
                            </ul>
                        </li>
                        <li class="nav-item dmenu">
                            <a href="javascript:void(0);" class="nav-link">CRM <i class='bx bx-plus'></i></a>

                            <ul class="dropdown-menu bigmenu">
                                <!-- <li class="nav-item"><a routerLink="/webdevelopment">Web Development</a></li> -->

                                <div class="wpmm-sub-menu-wrap">
                                    <div><ul>
                                    <li class="wpmega-1columns-2total">
                                        
                                        <a class="wp-mega-menu-link" routerLink="/salesforce-development-service"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/Developer-training.png" width="30" height="30"></span><span><b>Salesforce Development Service</b><p>Transforming Business with Salesforce Solutions</p></span></a></li>
                                    
                                    
                                    <li class="wpmega-1columns-2total">
                                        
                                        <a class="wp-mega-menu-link" routerLink="/salesforce-sales-cloud-service"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/Salesforce-sales-Cloud-services.png" width="30" height="30"></span><span><b>Salesforce Sales Cloud Service</b><p>Streamline Sales with Salesforce Sales Cloud</p></span></a></li>
                                    
                                    <li class="wpmega-1columns-2total">
                                        
                                        <a class="wp-mega-menu-link" routerLink="/salesforce-cpq-service"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/Salesforce-CPQ-services.png" width="30" height="30"></span><span><b>Salesforce CPQ Service</b><p>Streamline Your Sales Process with Efficiency</p></span></a></li>
                                        
                                        
                                    <li class="wpmega-1columns-2total">
                                            
                                        <a class="wp-mega-menu-link" routerLink="/salesforce-einstein-analytics-service"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/Salesforce-Einstein-Analytics-Services.png" width="30" height="30"></span><span><b>Salesforce Einstein Analytics Service</b><p>Unlock Insights with Salesforce Einstein Analytics</p> </span></a></li>
                                    
                                    <li class="wpmega-1columns-2total">
                                        
                                        <a class="wp-mega-menu-link" routerLink="/salesforce-marketing-cloud-service"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/Salesforce-marketing-cloud-Services.png" width="30" height="30"></span><span><b>Salesforce Marketing Cloud Service</b><p>Transform Your Customer Engagement with Salesforce</p> </span></a></li>
                                        
                                        
                                    <li class="wpmega-1columns-2total">
                                            
                                        <a class="wp-mega-menu-link" routerLink="/salesforce-field-service-lightning"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/Salesforce-Field-services.png" width="30" height="30"></span><span><b>Salesforce Field Service Lightning</b><p>Optimize Your Workforce with Salesforce Field Service</p></span></a>
                                    </li>
                                    <li class="wpmega-1columns-2total">
                                            
                                        <a class="wp-mega-menu-link" routerLink="/salesforce-administrator-training"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/smm.png" width="30" height="30"></span><span><b>Salesforce Administrator Training</b><p>Master Salesforce Administration Skills Today</p></span></a>
                                    </li>
                                    <li class="wpmega-1columns-2total">
                                            
                                        <a class="wp-mega-menu-link" routerLink="/salesforce-developer-training"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/Salesforce-Administrator-Training.png" width="30" height="30"></span><span><b>Salesforce Developer Training</b><p>Master Salesforce Development with Confidence</p></span></a>
                                    </li>
                                    <li class="wpmega-1columns-2total">
                                            
                                        <a class="wp-mega-menu-link" routerLink="/salesforce-lightning-components"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/Lightning-Components.png" width="30" height="30"></span><span><b>Salesforce Lightning Components</b><p>Enhance Your Apps with Lightning Components</p></span></a>
                                    </li>
                                    <li class="wpmega-1columns-2total">
                                            
                                        <a class="wp-mega-menu-link" routerLink="/zoho-crm-implementation-service"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/CRM-Implementation.png" width="30" height="30"></span><span><b>  Zoho CRM Implementation Service</b><p>Streamlining Your Business with Zoho CRM</p></span></a>
                                    </li>
                                </ul></div>
                                </div>
                               
                            </ul>
                        </li>
                        <li class="nav-item mmenu">
                            <a href="javascript:void(0);" class="nav-link">CRM <i class='bx bx-plus'></i></a>
                            
                            <ul>
                                                            <!-- <li class="nav-item"><a routerLink="/webdevelopment">Web Development</a></li> -->
                            
                                                           
                                                                <li class="wpmega-1columns-2total">
                                                                    
                                                                    <a class="wp-mega-menu-link" routerLink="/webdevelopment"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/webdev.png" width="30" height="30"></span><span><b>Salesforce Development Service</b></span></a></li>
                                                                
                                                                
                                                                <li class="wpmega-1columns-2total">
                                                                    
                                                                    <a class="wp-mega-menu-link" routerLink="/appdevelopment"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/appdev.png" width="30" height="30"></span><span><b>Salesforce Sales Cloud Service</b></span></a></li>
                                                                
                                                                <li class="wpmega-1columns-2total">
                                                                    
                                                                    <a class="wp-mega-menu-link" routerLink="/digitalmarketing"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/dm.png" width="30" height="30"></span><span><b>Salesforce CPQ Service</b> </span></a></li>
                                                                    
                                                                    
                                                                <li class="wpmega-1columns-2total">
                                                                        
                                                                    <a class="wp-mega-menu-link" routerLink="/graphicdesigning"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/graphic.png" width="30" height="30"></span><span><b>Salesforce Einstein Analytics Service</b> </span></a></li>
                                                                
                                                                <li class="wpmega-1columns-2total">
                                                                    
                                                                    <a class="wp-mega-menu-link" routerLink="/seo"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/seo.png" width="30" height="30"></span><span><b>Salesforce Marketing Cloud Service</b> </span></a></li>
                                                                    
                                                                    
                                                                <li class="wpmega-1columns-2total">
                                                                        
                                                                    <a class="wp-mega-menu-link" routerLink="/smm"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/smm.png" width="30" height="30"></span><span><b>Salesforce Field Service Lightning</b></span></a>
                                                                </li>
                                                                <li class="wpmega-1columns-2total">
                                                                        
                                                                    <a class="wp-mega-menu-link" routerLink="/smm"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/smm.png" width="30" height="30"></span><span><b>Salesforce Administrator Training</b></span></a>
                                                                </li>
                                                                <li class="wpmega-1columns-2total">
                                                                        
                                                                    <a class="wp-mega-menu-link" routerLink="/smm"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/smm.png" width="30" height="30"></span><span><b>Salesforce Developer Training</b></span></a>
                                                                </li>
                                                                <li class="wpmega-1columns-2total">
                                                                        
                                                                    <a class="wp-mega-menu-link" routerLink="/smm"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/smm.png" width="30" height="30"></span><span><b>Salesforce Lightning Components</b></span></a>
                                                                </li>
                                                                <li class="wpmega-1columns-2total">
                                                                        
                                                                    <a class="wp-mega-menu-link" routerLink="/smm"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/smm.png" width="30" height="30"></span><span><b>  Zoho CRM Implementation Service</b></span></a>
                                                                </li>
                                                           
                                                           
                                                        </ul>
                            </li>
                        <li class="nav-item mmenu">
                            <a href="javascript:void(0);" class="nav-link">Services <i class='bx bx-plus'></i></a>

                            <ul>
                                <li class="wpmega-1columns-2total">
                                    
                                    <a class="wp-mega-menu-link" routerLink="/webdevelopment"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/webdev.png" width="30" height="30"></span><span><b>Web Development</b> <br> <p>We create an engaging website</p></span></a></li>
                                
                                
                                <li class="wpmega-1columns-2total">
                                    
                                    <a class="wp-mega-menu-link" routerLink="/appdevelopment"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/appdev.png" width="30" height="30"></span><span><b>App Development</b> <br> <p>We develop robust and secure applications</p></span></a></li>
                                
                                <li class="wpmega-1columns-2total">
                                    
                                    <a class="wp-mega-menu-link" routerLink="/digitalmarketing"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/dm.png" width="30" height="30"></span><span><b>Digital Marketing</b> <br> <p>We create an unbeatable digital marketing strategy</p></span></a></li>
                                    
                                    
                                <li class="wpmega-1columns-2total">
                                        
                                    <a class="wp-mega-menu-link" routerLink="/graphicdesigning"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/graphic.png" width="30" height="30"></span><span><b>Graphic Designing</b> <br> <p>Create graphics that tell your brands </p></span></a></li>
                                
                                <li class="wpmega-1columns-2total">
                                    
                                    <a class="wp-mega-menu-link" routerLink="/seo"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/seo.png" width="30" height="30"></span><span><b>Search Engine Optimization</b> <br> <p>Be No. 1 on the Search Engine Results Page (SERP)</p></span></a></li>
                                    
                                    
                                <li class="wpmega-1columns-2total">
                                        
                                    <a class="wp-mega-menu-link" routerLink="/smm"><span class="wpmm-mega-menu-icon"><img src="assets/img/menu-icon/smm.png" width="30" height="30"></span><span><b>Social Media Marketing</b> <br> <p>We do successful creative social media marketing</p></span></a></li>

                            </ul>
                        </li>





                        








                            

                        <li class="nav-item"><a routerLink="/products" class="nav-link">Our Products</a></li>


                        <li class="nav-item">
                           <a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a>

                          
                        </li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>

                    <div class="side-nav d-in-line align-items-center">
                        <div class="side-item">
                            <div class="nav-add-btn">
                                <a routerLink="/contact" class="nav-menu-btn">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="side-nav-responsive">
        <div class="container">
            <div class="dot-menu">
                
               <div class="mymobilemenu">
                   <div class="side-nav justify-content-center  align-items-center">
                    <a routerLink="/contact" class="nav-menu-btn">Contact Us</a>
                   </div>
               </div>
                <!-- <div class="circle-inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div> -->
            </div>
            
            <!-- <div class="container">
                <div class="side-nav-inner">
                    <div class="side-nav justify-content-center  align-items-center">
                        <div class="side-item">
                            <div class="search-box">
                                <i class="flaticon-loupe"></i>
                            </div>
                        </div>
                        
                        <div class="side-item">
                            <div class="nav-add-btn">
                                <a routerLink="/contact" class="nav-menu-btn">Contact Us <i class='bx bx-plus'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            
            <div class="search-close">
                <span class="search-close-line"></span>
                <span class="search-close-line"></span>
            </div>

            <div class="search-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class="flaticon-loupe"></i></button>
                </form>
            </div>
        </div>
    </div>
</div>