import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-salesforce-cpq-service',
  templateUrl: './salesforce-cpq-service.component.html',
  styleUrls: ['./salesforce-cpq-service.component.scss']
})
export class SalesforceCPQServiceComponent implements OnInit {

  constructor(
    private titleService: Title,  
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Expert Salesforce CPQ Service | Avitrons USA");  
    
    this.metaTagService.addTags([  
      { name: 'description', content: 'Streamline sales with Avitrons Salesforce CPQ service in the USA. Automate quoting, pricing, and product configuration for faster, accurate sales.'},  
      { name: 'robots', content: 'index, follow' },  
      { charset: 'UTF-8' } , 
    ]); 
  }

}
