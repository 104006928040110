<app-navbar-style-two></app-navbar-style-two>
<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Salesforce Sales Cloud Service</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Salesforce Sales Cloud</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/img/crm/Salesforce-Sales-Cloud-Banner.jpg" alt="Images">
                        <h2>Transforming Sales with Salesforce Sales Cloud</h2>
                        <p>Experience a revolution in sales management with Salesforce Sales Cloud. Our solution enhances efficiency, fosters collaboration, and provides actionable insights, empowering your team to achieve unprecedented growth and customer satisfaction.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceSalesCloudService/transform-your-business.png" alt="Images">
                                </div>
                                <h3>Transform Your Business with Salesforce</h3>
                                <p>Unlock your business potential with Salesforce, driving efficiency, collaboration, and growth through innovative CRM solutions.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceSalesCloudService/custom-development.png" alt="Images">
                                </div>
                                <h3>Streamline Processes through Custom Development</h3>
                                <p>Leverage custom Salesforce development to automate workflows, improve efficiency, and simplify your business processes seamlessly.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceSalesCloudService/enhance-user-experience.png" alt="Images">
                                </div>
                                <h3>Enhance User Experience and Engagement</h3>
                                <p>Boost user satisfaction and engagement with personalized experiences, intuitive interfaces, and seamless navigation in Salesforce.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <div class="iconimg">
                                    <img src="assets/img/crm-icon/SalesforceSalesCloudService/boost-sales.png" alt="Images">
                                </div>
                                <h3>Boost Sales Performance Through Customization</h3>
                                <p>Customize your Salesforce environment to boost sales performance, streamline processes, and enhance team productivity effectively.</p>
                            </div>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/crm/Salesforce Sales-Cloud-Image.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="content-widget-text">
                                    <h2>Unlock Sales Potential Today</h2>
                                    <ul>
                                        <li><i class='bx bx-check'></i> Streamlined Sales Processes</li>
                                        <li><i class='bx bx-check'></i> Data-Driven Insights</li>
                                        <li><i class='bx bx-check'></i> Enhanced Customer Engagement</li>
                                        <li><i class='bx bx-check'></i> Collaborative Team Workflows</li>
                                        <li><i class='bx bx-check'></i> Empower Your Sales Team</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>Avitron provides a website powered by Salesforce Sales Cloud Service, enabling you to effectively showcase your products or services to potential customers. Seize the opportunity to boost your sales with a dynamic website. Whether you need a new site or a revamp, we have the expertise to deliver exceptional solutions.</p>

                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <app-sidebar></app-sidebar>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>


                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer-style-two></app-footer-style-two>