<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contact Us</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="contact-area pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Contact</span>
            <h2>Get in Touch</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-planet-earth"></i>
                    <h3>Office Location</h3>
                    <p>4810 Ranchview Ln N Plymouth,</p>
                    <p> MN 55446 </p>
                    <a target="_blank" href="#" class="contact-card-btn">Direction <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-email"></i>
                    <h3>Contact</h3>
                    <p>Email.info@avitrons.com</p>
                    <p>Mobile: +1 612 380 1233</p>
                    <a routerLink="/" class="contact-card-btn">Know More <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-card">
                    <i class="flaticon-clock"></i>
                    <h3>Hours of Operation</h3>
                    <p>Monday - Friday: 09:00 - 20:00</p>
                    <p>Sunday & Saturday: 10:30 - 22:00</p>
                    <a href="mailto:info@ribo.com" class="contact-card-btn">Support <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="contact-ection pt-100 pb-70">
  <div class="container-fluid">
    
    

   <div class="row">
    <div class="col-lg-5 col-sm-12">
    <div class="contact-img">
        <img src="assets/newimg/Contact.png" alt="Images">
    </div>
    </div>
    <div class="col-lg-7 col-md-12">
        <div class="contact-wrap">
            <iframe src="https://leads.avitrons.com/enquiry.php?doaminId=671744ae38e1d" width="100%" height="500" frameborder="0" scrolling="auto"></iframe>
        </div>
    </div>
   </div>




  </div>
</div>



<div class="contact-map">
    <div class="container-fluid m-0 p-0">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2819.173578538982!2d-93.47967162495881!3d45.04169936153126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52b3490cbd51fb15%3A0x7c0342672daffd5b!2s4810%20Ranchview%20Ln%20N%2C%20Plymouth%2C%20MN%2055446%2C%20USA!5e0!3m2!1sen!2sin!4v1729680547158!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>    
    
    </div>
</div>


<app-footer-style-two></app-footer-style-two>